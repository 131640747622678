import React from 'react'
import styled from 'styled-components'
import media from 'styles/media'
import { graphql, PageProps } from 'gatsby'

import { Layout } from 'views/Layout'
import { Seo } from 'components/atoms/Seo'

import { Container, ViewContainer } from 'components/atoms/Container'
import { SalesOffice } from 'components/modules/SalesOffice'
import { ContactTiles } from 'components/modules/ContactTiles'
import { LocationMap } from 'components/modules/LocationMap'
import { Breadcrumbs } from 'components/modules/Breadcrumbs'

const BreadcrumbsWraper = styled(Container)`
  max-width: calc(1136px + 48px);

  margin-top: 95px;

  ${media.sm.max} {
    margin-top: 58px;
    padding: 0px;
  }
`

const KontaktPage: React.FC<PageProps<Queries.KontaktPageQuery>> = ({
  data,
}) => {
  const PAGE = data.wpPage?.contact
  const PAGE_SEO = data.wpPage?.seo!

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const CONTACT_TILES = PAGE.contactSalersTiles
    ?.filter((tile) => tile?.contactSalersTilesDepartment)
    ?.map((tile) => ({
      name: tile?.contactSalersTilesName!,
      position: tile?.contactSalersTilesPosition!,
      phone: tile?.contactSalersTilesPhoneNumber!,
      email: tile?.contactSalersTilesEmail!,
      department: tile?.contactSalersTilesDepartment! as string[],
      image: {
        src: tile?.contactSalersTilesImg?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: tile?.contactSalersTilesImg?.altText!,
      },
    }))!

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      <ViewContainer initialHidden breakpoint="lg">
        <BreadcrumbsWraper padding={[0, 24]} margin={[0, 'auto']}>
          <Breadcrumbs crumbs={[{ name: 'Kontakt' }]} />
        </BreadcrumbsWraper>
      </ViewContainer>

      <SalesOffice
        heading={PAGE.contactHeroTitle!}
        companyName={PAGE.contactCompanyName!}
        companyAddress={PAGE.contactLocation!}
        companyPhone={PAGE.contactPhoneNumber!}
        companyEmail={PAGE.contactEmail!}
        companyOpeningHours={PAGE.contactOpeningHours!}
      />
      <ContactTiles heading={PAGE.contactSalersTitle!} tiles={CONTACT_TILES} />
      <LocationMap
        heading={PAGE.contactOfficeTitle!}
        routeLink="https://goo.gl/maps/4MnEgryAWke582Tp8"
        locationCoords={{
          lat: Number(PAGE.contactOfficeLocation?.contactOfficeLocationLat!),
          lng: Number(PAGE.contactOfficeLocation?.contactOfficeLocationLng!),
        }}
      />
    </Layout>
  )
}

export default KontaktPage

export const query = graphql`
  query KontaktPage {
    wpPage(slug: { regex: "/kontakt/" }) {
      contact {
        contactCompanyName
        contactHeroTitle
        contactLocation
        contactOfficeTitle
        contactOpeningHours
        contactPhoneNumber
        contactEmail
        contactSalersTitle
        contactSalersTiles {
          contactSalersTilesEmail
          contactSalersTilesName
          contactSalersTilesPhoneNumber
          contactSalersTilesPosition
          contactSalersTilesDepartment
          contactSalersTilesImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 200)
              }
            }
          }
        }
        contactOfficeLocation {
          contactOfficeLocationLat
          contactOfficeLocationLng
        }
      }
      seo {
        ...WpSEO
      }
    }
  }
`
