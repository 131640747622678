import React, { useState } from 'react'

import { H1Light, H4Bold, TextBody2 } from 'components/atoms/Typography'
import { LazyImage } from 'components/atoms/Image/LazyImage'

import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg'
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg'

import { ImageType } from 'types/image'

import { OptionButton } from 'components/atoms/Button'
import {
  ContactTilesContainer,
  Wrapper,
  TilesWrapper,
  TileWrapper,
  TileImageWrapper,
  TileContentWrapper,
  ContactLink,
  Line,
  OptionsConatiner,
} from './ContactTiles.styles'

type Props = {
  heading: string
  tiles: Array<{
    name: string
    position: string
    phone: string
    email: string
    image: ImageType
    department: string[]
  }>
}

export const ContactTiles: React.FC<Props> = ({ heading, tiles }) => {
  const DEPARTMENTS = Array.from(
    new Set(tiles.flatMap((image) => image.department))
  )

  const [selectedDepartment, setSelectedDepartment] = useState<string>(
    DEPARTMENTS[0]
  )

  return (
    <ContactTilesContainer padding={[0, 40]} margin={[0, 'auto']}>
      <Wrapper>
        <H1Light as="h2" dangerouslySetInnerHTML={{ __html: heading }} />

        <OptionsConatiner>
          {DEPARTMENTS.map((category) => (
            <OptionButton
              selected={selectedDepartment === category}
              onClick={() => setSelectedDepartment(category)}
            >
              {category}
            </OptionButton>
          ))}
        </OptionsConatiner>

        <TilesWrapper>
          {tiles
            ?.filter((image) => image.department.includes(selectedDepartment))
            .map(({ name, position, phone, email, image }, index) => (
              <>
                <TileWrapper key={`${name}-${index}`}>
                  <TileImageWrapper>
                    <LazyImage
                      src={image?.src}
                      alt={image.alt!}
                      fill
                      objectFit="cover"
                    />
                  </TileImageWrapper>
                  <TileContentWrapper>
                    <H4Bold dangerouslySetInnerHTML={{ __html: name }} />
                    <TextBody2 dangerouslySetInnerHTML={{ __html: position }} />
                    <ContactLink href={`tel:${phone}`}>
                      <PhoneIcon />
                      <TextBody2 dangerouslySetInnerHTML={{ __html: phone }} />
                    </ContactLink>
                    <ContactLink href={`mailto:${email}`}>
                      <EmailIcon />
                      <TextBody2 dangerouslySetInnerHTML={{ __html: email }} />
                    </ContactLink>
                  </TileContentWrapper>
                </TileWrapper>
                <Line />
              </>
            ))}
        </TilesWrapper>
      </Wrapper>
    </ContactTilesContainer>
  )
}
