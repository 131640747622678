import React from 'react'
import { Button, ButtonLine } from 'components/atoms/Button'
import { H1Light } from 'components/atoms/Typography'
import { Map } from 'components/modules/LocationMap/Map'
import { Wrapper, HeadingWrapper } from './LocationMap.styles'

type Props = {
  heading: string
  routeLink: string
  locationCoords: {
    lat: number
    lng: number
  }
}

export const LocationMap: React.FC<Props> = ({
  heading,
  routeLink,
  locationCoords,
}) => {
  return (
    <Wrapper>
      <HeadingWrapper>
        <H1Light dangerouslySetInnerHTML={{ __html: heading }} />
        <Button TextButton2 as="a" href={routeLink} target="_blank">
          <ButtonLine>Wyznacz trasę</ButtonLine>
        </Button>
      </HeadingWrapper>
      <Map locationCoords={locationCoords} />
    </Wrapper>
  )
}
