import React from 'react'

import { ViewContainer } from 'components/atoms/Container'
import { H1Light, H3Bold, TextBody1 } from 'components/atoms/Typography'

import { ReactComponent as PhoneIcon } from 'assets/icons/phone.svg'
import { ReactComponent as EmailIcon } from 'assets/icons/email.svg'
import { ReactComponent as AddressIcon } from 'assets/icons/address.svg'
import { ReactComponent as OpeningHoursIcon } from 'assets/icons/openingHours.svg'

import { Breadcrumbs } from 'components/modules/Breadcrumbs'
import { FormItem } from 'components/organisms/Contact/Form'

import {
  SalesOfficeContainer,
  Wrapper,
  SalesOfficeWrapper,
  FormWrapper,
  ContactItem,
  IconWrapper,
} from './SalesOffice.styles'

type Props = {
  heading: string
  companyName: string
  companyAddress: string
  companyPhone: string
  companyEmail: string
  companyOpeningHours: string
}

export const SalesOffice: React.FC<Props> = ({
  heading,
  companyName,
  companyAddress,
  companyPhone,
  companyEmail,
  companyOpeningHours,
}) => {
  return (
    <SalesOfficeContainer padding={[0, 40]} margin={[0, 'auto']}>
      <Wrapper>
        <SalesOfficeWrapper>
          <ViewContainer breakpoint="lg">
            <Breadcrumbs crumbs={[{ name: 'Kontakt' }]} />
          </ViewContainer>

          <H1Light as="h1" dangerouslySetInnerHTML={{ __html: heading }} />
          <H3Bold dangerouslySetInnerHTML={{ __html: companyName }} />
          <ContactItem>
            <IconWrapper>
              <AddressIcon />
            </IconWrapper>
            <TextBody1 dangerouslySetInnerHTML={{ __html: companyAddress }} />
          </ContactItem>
          <ContactItem>
            <IconWrapper>
              <PhoneIcon />
            </IconWrapper>
            <TextBody1 dangerouslySetInnerHTML={{ __html: companyPhone }} />
          </ContactItem>
          <ContactItem>
            <IconWrapper>
              <EmailIcon />
            </IconWrapper>
            <TextBody1 dangerouslySetInnerHTML={{ __html: companyEmail }} />
          </ContactItem>
          <ContactItem>
            <IconWrapper>
              <OpeningHoursIcon />
            </IconWrapper>
            <TextBody1
              dangerouslySetInnerHTML={{ __html: companyOpeningHours }}
            />
          </ContactItem>
        </SalesOfficeWrapper>

        <FormWrapper>
          <FormItem withDecoration formTitle="Napisz Do nas, już teraz!" />
        </FormWrapper>
      </Wrapper>
    </SalesOfficeContainer>
  )
}
