import styled from 'styled-components'
import media from 'styles/media'

export const Wrapper = styled.div`
  padding: 108px 0;

  ${media.lg.max} {
    padding: 48px 0;
  }
`
export const HeadingWrapper = styled.div`
  display: flex;
  gap: 48px;
  justify-content: center;
  margin-bottom: 48px;

  br {
    display: none;
  }

  ${media.lg.max} {
    gap: 0;
    flex-direction: column;
    margin-bottom: 32px;
    padding: 0 24px;

    a {
      margin-top: 24px;
    }

    br {
      display: block;
    }
  }
`

export const MapWrapper = styled.div`
  height: 580px;
  padding: 0 40px;
  margin: 0 auto;

  ${media.lg.max} {
    height: 480px;
    padding: 0;
  }
`
