import styled from 'styled-components'
import media from 'styles/media'

import { H1Light } from 'components/atoms/Typography'
import { Container } from 'components/atoms/Container'

export const ContactTilesContainer = styled(Container)`
  ${media.sm.max} {
    padding: 0 24px;
  }
`

export const Wrapper = styled.div`
  padding: 100px 0;

  ${media.sm.max} {
    padding: 48px 0;
  }
`

export const OptionsConatiner = styled.div`
  width: fit-content;

  margin-top: 24px;
  margin-right: auto;

  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export const TilesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 32px;
  row-gap: 40px;
  margin-top: 48px;

  ${H1Light} {
    br {
      display: none;
    }
  }

  ${media.sm.max} {
    grid-template-columns: 1fr;
    column-gap: 0;
    row-gap: 0;
    margin-top: 32px;

    ${H1Light} {
      br {
        display: block;
      }
    }
  }
`

export const TileWrapper = styled.div`
  display: flex;

  ${media.lg.max} {
    flex-direction: column;
  }
`

export const TileImageWrapper = styled.div`
  width: 148px;
  height: 148px;

  ${media.lg.max} {
    margin-bottom: 16px;
  }

  ${media.sm.max} {
    width: 96px;
    height: 96px;
  }
`

export const TileContentWrapper = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${media.lg.max} {
    margin-left: 0;
  }
`

export const ContactLink = styled.a`
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.gray0};
  margin-top: 10px;

  svg {
    margin-right: 10px;
  }
`

export const Line = styled.div`
  display: none;

  ${media.sm.max} {
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.gray10};
    margin: 32px 0;

    &:last-child {
      margin: 32px 0 0;
    }
  }
`
