import React from 'react'
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import mapStyles from 'assets/misc/mapStyles'

import useBreakpoint from 'hooks/useBreakpoint'

import { MapWrapper } from './LocationMap.styles'

type Props = {
  locationCoords: {
    lat: number
    lng: number
  }
}

export const Map: React.FC<Props> = ({ locationCoords }) => {
  const { lg } = useBreakpoint()

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCjuSiBajzm2xFw5L5ag525NhtbITpO94g',
  })

  const renderMap = () => {
    return (
      <MapWrapper>
        <GoogleMap
          options={{ styles: mapStyles }}
          mapContainerStyle={{ width: '100%', height: '100%' }}
          center={locationCoords}
          zoom={14}
        >
          <Marker
            position={locationCoords}
            icon={{
              url: '/miasteczko-jutrzenki-pin.svg',
              scaledSize: lg
                ? new window.google.maps.Size(128, 128)
                : new window.google.maps.Size(80, 80),
            }}
            title="Biuro sprzedaży"
          />
        </GoogleMap>
      </MapWrapper>
    )
  }

  if (loadError) {
    return <div>Przepraszamy, mapa nie mogła zostać teraz załadowana.</div>
  }

  return isLoaded ? renderMap() : <div>Wczytywanie mapy...</div>
}
