import styled from 'styled-components'
import media from 'styles/media'
import { H1Light, H3Bold } from 'components/atoms/Typography'
import { Container } from 'components/atoms/Container'

export const SalesOfficeContainer = styled(Container)`
  ${media.lg.max} {
    padding: 0 24px;
  }
`

export const Wrapper = styled.div`
  margin: 97px auto 0;
  padding: 72px 0;
  display: grid;
  grid-template-columns: 5fr 4fr;
  gap: 64px;

  ${media.lg.max} {
    gap: 0;
    grid-template-columns: repeat(2, 1fr);
  }

  ${media.lg.max} {
    grid-template-columns: 1fr;
    padding: 0 0 48px 0;
    gap: 32px;
    margin-top: 0px;
  }
`

export const SalesOfficeWrapper = styled.div`
  ${H1Light} {
    padding: 48px 0;
  }

  ${H3Bold} {
    margin-bottom: 32px;

    br {
      display: none;
    }
  }

  ${media.lg.max} {
    width: 100%;
    max-width: 500px;

    margin: 0px auto;
    padding-bottom: 32px;

    ${H1Light} {
      padding: 48px 0 32px 0;
    }

    ${H3Bold} {
      br {
        display: block;
      }
    }
  }
`

export const FormWrapper = styled.div`
  ${media.lg.max} {
    > div {
      min-width: unset;
    }
  }
`

export const ContactItem = styled.div`
  display: flex;

  &:not(&:last-of-type) {
    margin-bottom: 20px;
  }
`

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;

  ${media.lg.max} {
    width: 24px;
    height: 24px;
  }
`
